<template>
    <TemplateBase
        :theme="theme"
        :theme-variant="themeVariant"
    >
        <DelayHydration>
            <template #default>
                <div class="theme-pink-teal theme-dark">
                    <BaseSection>
                        <template #title>Welcome</template>
                        <template #default>
                            <div class="content-cta">
                                <h1> Theme show</h1>
                                <p> Ander thema is zichtbaar door dit blok</p>
                            </div>
                        </template>
                    </BaseSection>
                </div>
                
                <BaseSection class="content-cta">
                    <template #title>Welcome</template>
                    <template #default>
                        <BaseRichText>
                            <p>Welcome to the Digital Natives content boilerplate.</p>
                            <p>Run your histoire styleguide by hitting <strong>pnpm story:dev</strong> in your terminal.</p>

                            <ul>
                                <li>
                                    <nuxt-link to="/">Home NL</nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/en">Home EN</nuxt-link>
                                </li>
                            </ul>
                        </BaseRichText>
                    </template>
                </BaseSection>

                <BaseSection>
                    <template #title>Entry</template>
                    <template #default>
                        <pre>{{ entry }}</pre>
                    </template>
                </BaseSection>
            </template>
        </DelayHydration>
    </TemplateBase>
</template>

<script setup>
import HomeQuery from '~/graphql/queries/Home.graphql';

const { variables }  = useCraftGraphql();
// We don't need the URI for this query
delete variables.uri;
const response = await useAsyncQuery({
    query: HomeQuery,
    variables
});
const { craftEntry: entry } = useCraftPage(response);

// TODO: make dynamic when CMS is ready
const theme = entry?.value.selectedTheme;
const themeVariant = entry?.value.themeVariant;
</script>

<style lang="less">
.content-cta {
    background-color: var(--theme-background);
    color: var(--theme-color);
}
</style>

